import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/default-page.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Brick Effect Render`}</h1>
    <p>{`Brick effect render is a versatile alternative to traditional brickwork and is ideal for use on projects where traditional new brick-work is impractical.`}</p>
    <p>{`Brick effect render is applied in two coats. Firstly a wet coloured base coat is applied followed by a complimentary coloured wet top coat.`}</p>
    <p>{`The top coat is then cut through to expose the base coat ‘mortar’ layer, thus creating the brick effect finish.`}</p>
    <p>{`Brick effect render can be used on full elevations or simply as a method of adding an interesting detailing at building corners or window heads and is ideal for use on both render only projects and as a final finish for external wall insulation systems.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      